<template>
  <div style="width: 0;height: 0">
    <div :style="styles" class="canvas__window" v-show="$interpreter.showCanvasWindow">
      <div class="canvas__window_title" @mousedown="onMouseDown">
        <a href="#" class="canvas__window_title__close" @click="close">&times;</a>
      </div>
      <div>
        <div id="canvasContainer">
          <div :style="{
            'overflow': 'hidden',
            'height':'350px',
            'width': '350px'
          }" @click="handleCanvasClick" @contextmenu="handleCanvasRightClick">
            <canvas id="letpyCanvas" width="350" height="350" style="background-color: rgba(255,255,255,1)"></canvas>
          </div>
        </div>
      </div>
      <div id="hidden_canvas" style="display: none"></div>
    </div>

    <div id="virtualKeyboard" style="display: none;position:absolute;bottom:0;right:0;z-index: 100000;">
      <div class="virtual-keyboard-wrapper">
        <div class="virtual-keyboard-wrapper-row">
          <button  onclick="window.dispatchEvent(new KeyboardEvent('keydown', {'code': 'ArrowUp'}))">↑
          </button>
        </div>
        <div class="virtual-keyboard-wrapper-row">
          <button onclick="window.dispatchEvent(new KeyboardEvent('keydown', {'code': 'ArrowLeft'}))">←
          </button>
          <button onclick="window.dispatchEvent(new KeyboardEvent('keydown', {'code': 'ArrowDown'}))">↓
          </button>
          <button onclick="window.dispatchEvent(new KeyboardEvent('keydown', {'code': 'ArrowRight'}))">→
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Interpreter from "./Interpreter";

export default {
  name: 'canvas-window',
  data: () => ({
    top: 0,
    left: 0,
    startDragX: 0,
    startDragY: 0,
    isFirstRun: true
  }),

  created() {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keydown", this.handleCanvasKeyDown);
    window.addEventListener("keyup", this.handleCanvasKeyUp);
  },

  computed: {
    styles() {
      return {
        top: this.top.toString() + 'px',
        left: this.left.toString() + 'px',
      }
    }
  },

  methods: {
    close() {
      if (this.$interpreter.isRunning) {
        Interpreter.stopExecution();
      }
      Interpreter.hideCanvasWindow();
    },

    onMouseDown(e) {
      this.startDragX = e.clientX;
      this.startDragY = e.clientY;
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)

    },

    onMouseMove(e) {
      this.left += e.clientX - this.startDragX;
      this.top += e.clientY - this.startDragY;
      this.startDragX = e.clientX;
      this.startDragY = e.clientY;
    },

    onMouseUp() {
      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('mouseup', this.onMouseUp)
    },

    handleCanvasClick(event) {
      if (window._Canvas__click_event_handler_function) {
        window._Canvas__click_event_handler(window._Canvas__click_event_handler_function, event.layerX, event.layerY);
      }
    },

    handleCanvasRightClick(event) {
      if (window._Canvas__right_click_event_handler_function) {
        window._Canvas__right_click_event_handler(window._Canvas__right_click_event_handler_function, event.layerX, event.layerY);
        event.preventDefault();
      }
    },

    handleCanvasKeyUp(event) {
      if (window._Canvas__keyup_event_handler_functions !== undefined) {
        for (let i = 0; i < window._Canvas__keyup_event_handler_functions.length; i++) {
          let code = window._Canvas__keyup_event_handler_functions[i][0];
          let handler = window._Canvas__keyup_event_handler_functions[i][1];
          if ((code === 'all_keys_handler') || (code === event.code)) {
            window._Canvas__keyup_event_handler(handler, code, event.code);
          }
        }
      }
    },

    handleCanvasKeyDown(event) {
      if (window._Canvas__keydown_event_handler_functions !== undefined) {
        for (let i = 0; i < window._Canvas__keydown_event_handler_functions.length; i++) {
          let code = window._Canvas__keydown_event_handler_functions[i][0];
          let handler = window._Canvas__keydown_event_handler_functions[i][1];
          if ((code === 'all_keys_handler') || (code === event.code)) {
            window._Canvas__keydown_event_handler(handler, code, event.code);
          }
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keydown', this.handleCanvasKeyDown);
    window.removeEventListener('keyup', this.handleCanvasKeyUp);
  },

  watch: {
    '$interpreter.showCanvasWindow': function(newValue) {
      if (newValue && this.isFirstRun) {
        this.left = (window.innerWidth - 350) / 2;
        this.top = (window.innerHeight - 350 - 30) / 2;
        this.isFirstRun = false;
      }
    }
  }
}
</script>
<style>

@media only screen and (min-width: 720px) {
  .virtual-keyboard-wrapper {
    display: none;
  }
}

.virtual-keyboard-wrapper {
  background-color: rgba(1, 1, 1, 0.3);
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}

.virtual-keyboard-wrapper-row {
  width: 200px;
  text-align: center;
}

.virtual-keyboard-wrapper-row > button {
  margin: 4px;
  width: 50px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.v-application .canvas__window {
  z-index: 100000;
  position: absolute;
  background: #ddd;
  color: #000;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.v-application .canvas__window_title {
  padding:5px 10px;
  height: 30px;
  background: #0b75c9;
  cursor: pointer;
  color: #fff;
  text-align: right;
}

.v-application a.canvas__window_title__close {
  color: #fff;
  text-decoration: none;
}
</style>