<template>
  <transition name="fade" mode="out-in" appear>
      <div v-if="loading" class="d-flex justify-content-center align-items-center" style="background-color: #ccc; height: 100vh">
        <b-spinner type="grow" style="width: 3rem; height: 3rem;"></b-spinner>
      </div>
      <div v-else>
        <b-sidebar id="sidebar-1" :title="apps.app.title" shadow>
          <div class="px-3 py-2" v-html="apps.app.description">
          </div>
        </b-sidebar>

        <b-navbar toggleable="lg" :type="(apps.app.theme === 'dark') ? 'dark': 'light'" :variant="(apps.app.theme === 'dark') ? 'dark': 'light'">
          <b-navbar-brand v-b-toggle.sidebar-1>{{ apps.app.title }}</b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <div class="mr-auto"></div>
            <b-button @click="restart()" variant="success" >Перезапустить</b-button>

          </b-collapse>
        </b-navbar>
        <canvas-window></canvas-window>
        <tkinter></tkinter>

        <div ref="stdout" class="stdout" @click="$focusOnInputElement"
             :style=stdoutStyles>
          <pre v-html="$stdout" :class="apps.app.theme"></pre>
        </div>
      </div>
  </transition>
</template>

<script>
import {mapState} from "vuex";
import Interpreter from "@/plugins/interpreter/Interpreter";
import CanvasWindow from "@/plugins/interpreter/CanvasWindow";
import Tkinter from "@/plugins/interpreter/Tkinter";

export default {
  name: 'Application',
  components: {Tkinter, CanvasWindow},
  data: () => ({
    loading: false
  }),

  computed: {
    ...mapState(['apps']),

    stdoutStyles() {
      return {
        backgroundColor: (this.apps.app.theme === 'dark') ? `rgba(27, 27, 27, 1)` : `rgba(232, 232, 232, 1)`,
        fontSize: `${this.apps.app.fontSize}px`,
      }
    }
  },

  created() {
    this.fetchApp()
  },

  methods: {
    restart() {
      Interpreter.stopExecution();
      let interval = setInterval(()=>{
        if (!this.$interpreter.isRunning) {
          clearInterval(interval);
          Interpreter.runFile({
              content: this.apps.app.source,
              id: this.apps.app.vfsItem,
              parent: this.apps.app.vfsItemParent
          });
        }
      }, 100)

    },

    fetchApp() {
      this.loading = true
      this.$store.dispatch('apps/fetchAppBySlug', this.$route.params.slug).then(()=>{
        document.title = this.apps.app.title;
        this.$nextTick(() => {
          Interpreter.runFile({
            content: this.apps.app.source,
            id: this.apps.app.vfsItem,
            parent: this.apps.app.vfsItemParent
          });
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },

  watch: {
    '$route.params.slug'() {
      this.fetchApp()
    },

    '$stdout': function() {
      this.$nextTick(() => {
        this.$refs.stdout.scrollTop = this.$refs.stdout.scrollHeight;
      });
    }
  }
}
</script>
<style>
.stdout {
  height: calc(100vh - 56px);
  overflow: scroll;
  padding: 10px;
}

.stdout pre {
  margin: 0;
}
.stdout pre.dark {
  color :#ccc;
}

.stdout pre.light {
  color :#333;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.stdout span.blinked-cursor {
  opacity: 1;
  animation: blink 1s linear infinite alternate;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
