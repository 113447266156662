export default {
  render: function (h) {
    return h('div')
  },

  watch: {
    '$interpreter.isRunning': function (val) {
      if (!val) {
        let elements = document.getElementsByClassName('tkinter__window');
        while(elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    }
  }
}